import {authSelector, checkInSelector, homeSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import CheckInContainer from './checkInContainer';

const CheckIn = (props) => {
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const checkInSelect = useSelector(checkInSelector);

    const {checkInList = [], programNameList = []} = checkInSelect;
    const {userLoginInfo} = auth;
    const checkInContainerProps = {
        home,
        userLoginInfo,
        checkInList,
        programNameList
    };

    return <CheckInContainer {...checkInContainerProps} />;
};

export default CheckIn;
